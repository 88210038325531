import { Component, OnInit, Input } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  @Input()
  showBackButton = false;

  constructor(
      private navCtrl: NavController,
      private menu: MenuController
    ) { }

  ngOnInit() {}

  public goToUrl(url: string) {
    this.navCtrl.navigateRoot( '/' + url);
    this.menu.close('principal');
  }

}
