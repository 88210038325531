import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Recordatorio, Servicio } from '../interfaces/interfaces';
import { UsuarioService } from './usuario.service';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class RecordatorioService {

  constructor( private http: HttpClient,
    private usuarioService: UsuarioService) { }

  public getRecordatorioByCliente(idCliente: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Servicio[]>(`${ URL }/recordatorio/${ idCliente }`, {headers});
  }

  public getRecordatoriosHoy() {
    // DESDE LAS 00:00 DE HOY
    const currentDesde = new Date();
    currentDesde.setHours(0);
    currentDesde.setMinutes(0);
    currentDesde.setSeconds(0);
    currentDesde.setMilliseconds(0);
    const fechaDesde = currentDesde.toISOString();
    // HASTA LAS 23:59 HOY
    const currentHasta = new Date();
    currentHasta.setHours(23);
    currentHasta.setMinutes(59);
    currentHasta.setSeconds(0);
    currentHasta.setMilliseconds(0);
    const fechaHasta = currentHasta.toISOString();

    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Recordatorio[]>(`${ URL }/recordatorio/rec/fechas?fechaDesde=${ fechaDesde }&fechaHasta=${ fechaHasta }`, {headers});
  

  }
  public crearRecordatorio( recordatorio: Recordatorio) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise<boolean>( resolve => {
      this.http.post(`${ URL }/recordatorio/create`, recordatorio, {headers})
        .subscribe( resp => {
          if(resp['ok']) {
            resolve(true);
          }
          resolve(false);
        });
    });
  }

  public eliminar(recordatorioId: string) {
    return new Promise( resolve => {
      const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
      });
      return this.http.get<Recordatorio>(`${ URL }/recordatorio/delete/${ recordatorioId }`, {headers}).subscribe(resp =>{
        return resolve(true);
      }); 
    });
  }

  public mensajeEnviado( recordatorioId: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise<boolean>( resolve => {
      this.http.post(`${ URL }/recordatorio/notificadaWS/${ recordatorioId }`, {}, {headers})
        .subscribe( resp => {
          resolve(true);
      });
    });
  }

}