import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Recordatorio } from 'src/app/interfaces/interfaces';
import { RecordatorioService } from 'src/app/services/recordatorio.service';

@Component({
  selector: 'app-recordatorio',
  templateUrl: './recordatorio.component.html',
  styleUrls: ['./recordatorio.component.scss'],
})
export class RecordatorioComponent implements OnInit {

  @Input()
  public recordatorio:Recordatorio;

  @Input()
  public showCliente = true;

  @Output()
  returEvento = new EventEmitter<any>();

  constructor(public actionSheetController: ActionSheetController,
    private recordatorioService: RecordatorioService) { }

  ngOnInit() {}

  public async menu() {

    const actionSheet = await this.actionSheetController.create({
      header: 'RECORDATORIO',
      cssClass: 'my-custom-class',
      buttons:[{
        text: 'Eliminar',
        icon: 'trash',
        handler: () => {
          this.eliminar();
        },
      }]
    });
    await actionSheet.present();

  }

  private eliminar() {
    this.recordatorioService.eliminar(this.recordatorio._id).then(()=> {
      this.returEvento.emit(true);
    })
  }

}
