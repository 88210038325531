import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Cita, Cliente, Notificacion } from '../interfaces/interfaces';
import { UsuarioService } from './usuario.service';
import { citasEstadosConstants } from '../components/cita/citas.estados.constants';
import { DatePipe } from '@angular/common';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class ImagesService {


  constructor( private http: HttpClient,
    private usuarioService: UsuarioService) { }

  public crearCarpeta(idCliente:string, nombreCarpeta: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post(`${ URL }/images/crearcarpeta/${ idCliente }`, {nombreCarpeta: nombreCarpeta}, {headers});
  }

  public eliminarCarpeta(idCliente:string, nombreCarpeta: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post(`${ URL }/images/eliminarcarpeta/${ idCliente }`, {nombreCarpeta}, {headers});
  }

  public eliminarImagen(idCliente:string, nombreCarpeta: string, imagen: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post(`${ URL }/images/eliminarImagen/${ idCliente }`, {nombreCarpeta, imagen}, {headers});
  }

  public getCarpetas(idCliente:string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get(`${ URL }/images/mostrarcarpetas/${ idCliente }`, {headers});
  }

  public getImagesCarpetas(idCliente:string, nombreCarpeta:string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post(`${ URL }/images/imageslist/${ idCliente }`, {nombreCarpeta: nombreCarpeta}, {headers});
  }

  public subirImagen(formData: FormData, idCliente: string, carpeta: string) {
    const datos = {idCliente: idCliente, carpeta: carpeta};
    formData.append('datos', JSON.stringify(datos));
    this.usuarioService.cargarToken();
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/images/uploadImage`, formData, {headers})
        .subscribe( resp => {
          resolve(resp['ok']);
        });
    });
  }
  
}
