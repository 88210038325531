import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { Bono, Cita, Cliente, Notificacion, ProposicionCita, Servicio, Zona } from 'src/app/interfaces/interfaces';
import { CitaService } from 'src/app/services/cita.service';
import { BonoConstants } from '../bono/bono.constant';
import { citasEstadosConstants } from './citas.estados.constants';
import * as moment from 'moment';
import { BonoService } from 'src/app/services/bono.service';
import { ModalZonasComponent } from '../modal-zonas/modal-zonas.component';
import { PushService } from 'src/app/services/push.service';
import { ServiciosConstants } from './servicios.constant';
import { ModalFormularioCitaComponent } from '../modal-formulario-cita/modal-formulario-cita.component';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-cita',
  templateUrl: './cita.component.html',
  styleUrls: ['./cita.component.scss'],
})
export class CitaComponent implements OnInit {

  @Input()
  cita: Cita;

  @Input()
  showClienteBono: boolean = true;

  @Input()
  enProposicion: boolean = false;

  @Output()
  returEvento = new EventEmitter<Cita>();

  @Output()
  proponerCitas = new EventEmitter<Cita>();

  public cliente: Cliente;

  public servicio: Servicio;

  public bono: Bono;

  public bonosCliente: Bono[];

  public buttons: any[] = [];

  public nombreBono: string = '';

  public color: string = '';

  public hoy: string = '';

  public arrayEstadosCitas = citasEstadosConstants.arrayEstadosCitas;

  public estadoActual: string = '';

  public zonasString: string = '';

  public area: any;

  public proposiciones: ProposicionCita[];

  constructor(public actionSheetController: ActionSheetController,
    private modalCtrl: ModalController,
    private citaService: CitaService,
    private pushService: PushService,
    private alertController: AlertController,
    private datePipe: DatePipe,
    private bonoService: BonoService) { }

  ngOnInit() {
    this.cliente = this.cita.cliente as Cliente;
    this.servicio = this.cita.servicio as Servicio;
    this.getButonsByEstado();
    this.chargerColorStatus(); 
    this.area = ServiciosConstants.areas.find(a => a.id === this.servicio.grupo);
    
    if ((this.servicio.tipoBono === '1' && !this.cita.revision) || this.servicio.nombre === 'Cera') {
      const zonarray: string[] = JSON.parse(this.cita.zonas);
      this.zonasString = zonarray?.toString().replace(/,/g, ', ');
    }
    this.hoy = moment().format();
    this.nombreBono = this.servicio.nombre;
    if(this.cita.estado === citasEstadosConstants.PTE_PROPUESTA && !this.enProposicion) {
      this.proposiciones = JSON.parse(this.cita.proposiciones);
    }
  }


  async menu() {
    if(!this.enProposicion) {
      const actionSheet = await this.actionSheetController.create({
        header: 'CITA',
        cssClass: 'my-custom-class',
        buttons: this.buttons
      });
      await actionSheet.present();

      await actionSheet.onDidDismiss().then(()=>{
        
      });
    }
  }

  public async editarCita(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalFormularioCitaComponent,
      componentProps: {
        cita: this.cita,
        cliente: this.cliente
      }
    });
    modal.onDidDismiss().then(res => {
      this.getButonsByEstado();
      this.chargerColorStatus();
      this.returEvento.emit(this.cita);
    });
    return await modal.present();    
  }
 /* async guardar( fBono: NgForm ) {
    if ( fBono.invalid ) { console.log("FORMULARIO INVALIDO"); return; }
      console.log("FORMULARIO VALIDO");
      if(!this.editMode) {
        this.cita.bono = this.bono._id;
        if (this.bono && this.bono.tipo === '1') {
          this.cargarZonasEnCita();
        }
      }
    if (this.cita.estado === citasEstadosConstants.SOLICITUD || this.cita.estado === citasEstadosConstants.CAMBIO) {
      this.cita.estado = citasEstadosConstants.PROGRAMADA;
      this.enviarPush(this.cita.estado, '');
    } else {
      this.enviarPush(citasEstadosConstants.PROGRAMADA, 'NUEVA CITA');
    }
    if(this.cita.bono) {
      this.citaService.addOrUpdateCita(this.cita, this.bono as Bono).then(idCita => {
        if(idCita !== ''){
          this.cita._id = idCita;
        }
      })
    } else {
      this.citaService.actualizarCitaSimple(this.cita);
    }

  }*/

  private chargerColorStatus(): void {
    switch (this.cita.estado) {
      case citasEstadosConstants.SOLICITUD:
        this.color = 'secondary';
        break;
      case citasEstadosConstants.CAMBIO:
        this.color = 'success';
        break;
      case citasEstadosConstants.CANCELADA:
        this.color = 'danger';
        break;
      case citasEstadosConstants.NO_ACUDE:
        this.color = 'danger';
        break;
      case citasEstadosConstants.ACUDIDA:
        this.color = 'dark';
        break;
      case citasEstadosConstants.PTE_VALIDAR:
        this.color = 'warning';
        break;
      default:
        this.color = 'dark';
        break;
    }
  }

  private getButonsByEstado() {
    this.buttons = [];
    if (this.cita.estado === citasEstadosConstants.PROGRAMADA || 
        this.cita.estado === citasEstadosConstants.CAMBIO ||
        this.cita.estado === citasEstadosConstants.SOLICITUD) {
      this.buttons.push({
        text: 'Editar',
        icon: 'create',
        handler: () => {
          this.estadoActual = this.cita.estado;
          this.editarCita();
        },
      })
    }
    if (this.cita.estado === citasEstadosConstants.PTE_VALIDAR) {
      if(this.cita.bono && this.cita.bono !== '') {
        this.buttons.push({
          text: 'Descontar sesion',
          icon: 'checkmark',
          handler: () => {
            this.cita.revision = false;
            if (this.servicio.tipoBono !== '1') {
              this.cambiarEstado(citasEstadosConstants.ACUDIDA);
            } else {
              this.selectZonas();
            }
          },
        });
      } else {
        this.buttons.push({
          text: 'Acudida',
          icon: 'checkmark',
          handler: () => {
            this.cita.revision = false;
            this.cambiarEstado(citasEstadosConstants.ACUDIDA);
          },
        });
      }
      this.buttons.push({
        text: 'Pasar a Programada',
        icon: 'calendar',
        handler: () => {
          this.cambiarEstado(citasEstadosConstants.PROGRAMADA);
        },
      }),
      this.buttons.push({
        text: 'Solo revisión',
        icon: 'close',
        handler: () => {
          this.cita.revision = true;
          this.cambiarEstado(citasEstadosConstants.ACUDIDA);
        },
      })
      this.buttons.push({
        text: 'No acudida',
        icon: 'close',
        handler: () => {
          this.cita.revision = false;
          this.cambiarEstado(citasEstadosConstants.NO_ACUDE);
        },
      })
    }

    /*REVISAR ESTO:
            (this.cita.estado === citasEstadosConstants.CANCELADA && this.bono.sesiones_pendientes!=0)||
        (this.cita.estado === citasEstadosConstants.NO_ACUDE && this.bono.sesiones_pendientes!=0)
        */

    if (this.cita.estado === citasEstadosConstants.PROGRAMADA ||
        this.cita.estado === citasEstadosConstants.ACUDIDA ||
        (this.cita.estado === citasEstadosConstants.CANCELADA_GENERAL)||
        (this.cita.estado === citasEstadosConstants.NO_ACUDE)
      ) {
        this.buttons.push({
          text: 'Pendiente de Validar',
          icon: 'checkmark',
          handler: () => {
            this.cita.revision = false;
            this.cambiarEstado(citasEstadosConstants.PTE_VALIDAR);
          },
        })
    }
    if (this.cita.estado !== citasEstadosConstants.CANCELADA_GENERAL
      && this.cita.estado !== citasEstadosConstants.CANCELADA_RESTA) {
      this.buttons.push({
        text: 'Cancelar',
        icon: 'checkmark',
        handler: () => {
          this.cita.revision = false;
          this.cambiarEstado(citasEstadosConstants.CANCELADA_GENERAL);
        },
      });
    }
    if (this.cita.estado === citasEstadosConstants.CANCELADA_RESTA) {
      this.buttons.push({
        text: 'Cancelar y devolver sesión',
        icon: 'checkmark',
        handler: () => {
          this.cita.revision = false;
          this.cambiarEstado(citasEstadosConstants.CANCELADA_GENERAL);
        },
      });
    }
    if(this.cita.estado !== citasEstadosConstants.CANCELADA_RESTA && 
        this.cita.bono && this.cita.bono !== '') {
      this.buttons.push({
        text: 'Cancelar y descontar sesión',
        icon: 'close',
        handler: () => {
          this.cita.revision = false;
          this.cambiarEstado(citasEstadosConstants.CANCELADA_RESTA);
        },
      });
    }
    if(this.cita.estado === citasEstadosConstants.SOLICITUD ||
      this.cita.estado === citasEstadosConstants.PTE_PROPUESTA ||
      this.cita.estado === citasEstadosConstants.CAMBIO){
      this.buttons.push(        {
        text: 'Whatsapp',
        icon: 'logo-whatsapp',
        handler: () => {
          const url = 'https://wa.me/34'+ this.cliente.movil;
          window.open(url, "_blank");
        },
      });
      this.buttons.push(        {
        text: 'Proponer fechas',
        icon: 'calendar',
        handler: () => {
          this.proponerCitas.emit(this.cita);
        },
      });
    }
    this.buttons.push({
      text: 'Eliminar',
      icon: 'trash',
      handler: () => {
        this.preguntarEliminarCita();
      },
    });
}

public async preguntarEliminarCita() {
  const alert = await this.alertController.create({
    header: '¿Estas seguro de eliminar la cita con fecha ' + this.datePipe.transform(this.cita.fecha, 'dd/MM/yyyy HH:mm')+ '?',
    cssClass: 'custom-alert',
    buttons: [
      {
        text: 'NO',
        cssClass: 'alert-button-cancel',
      },
      {
        text: 'SI',
        cssClass: 'alert-button-confirm',
        handler: () => {
          this.citaService.eliminarCita(this.cita).then(resp => {
            this.returEvento.emit(this.cita);
          });
        }
      },
    ],
  });

  await alert.present();
}

  public cambiarEstado(estado: string) {
    //const citaUpdate = {_id: this.cita._id, estado: estado, revision: this.cita.revision};
    this.cita.estado = estado;
    if(this.cita.bono) {
      this.citaService.addOrUpdateCita(this.cita, this.cita.bono as Bono).then(()=>{
        this.getButonsByEstado();
        this.chargerColorStatus();
        if(estado === citasEstadosConstants.PROGRAMADA || estado === citasEstadosConstants.NO_ACUDE) {
          this.pushService.enviarPushSimple(estado, '',this.cliente, this.cita);
        }
        this.returEvento.emit(this.cita);
      });
    } else {
      this.citaService.actualizarCitaSimple(this.cita).then(() => {
        this.getButonsByEstado();
        this.chargerColorStatus();
        if(estado === citasEstadosConstants.PROGRAMADA || estado === citasEstadosConstants.NO_ACUDE) {
          this.pushService.enviarPushSimple(estado, '', this.cliente, this.cita);
        }
        this.returEvento.emit(this.cita);
      })
    }
  }

  private async selectZonas(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalZonasComponent,
      componentProps: {
        zonas: (this.cita.bono as Bono).zonas,
        zonasSelected: JSON.parse(this.cita.zonas)
      }
    });
    modal.onDidDismiss().then(res => {
      if (res.data.enviar) {
        this.cita.zonas = JSON.stringify(res.data.zonas);
        this.cambiarEstado(citasEstadosConstants.ACUDIDA)
      }
    });
    return await modal.present();
  }
/*
  private cargarZonasEnCita(): void {
    const zonasObj: Zona[] = JSON.parse(this.bono.zonas);
    let zonas: string[] = [];
    zonasObj.forEach(zona =>{
      if(zona.sesiones > 0) {
        zonas.push(zona.nombre); 
      }
    });
    this.cita.zonas = JSON.stringify(zonas);
  }
*/
}
