import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Cita, Cliente, Notificacion } from '../interfaces/interfaces';
import { UsuarioService } from './usuario.service';
import { citasEstadosConstants } from '../components/cita/citas.estados.constants';
import { DatePipe } from '@angular/common';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class PushService {


  constructor( private http: HttpClient,
    private datePipe: DatePipe,
    private usuarioService: UsuarioService) { }

  
    public enviarPushSimple(estado: string, titulo: string, cliente: Cliente, cita: Cita) {
      const mensaje = this.getMensajePush(estado, cita);
      if(titulo === '') {
        titulo = 'CAMBIOS EN SU CITA';
      }
      const notificacion: Notificacion = {
        titulo: titulo,
        texto: mensaje,
        cliente: cliente._id,
        pushId: cliente.pushId,
        tipo: 'cita'
      }
      this.envioSimple(notificacion);
    }
  
    private getMensajePush(estado: string, cita: Cita): string {
      let mensaje = '';
      switch (estado) {
        case citasEstadosConstants.PROGRAMADA:
          mensaje = 'Su cita ha sido programada para el día: '+
          this.datePipe.transform(cita.fecha, 'dd/MM/YYYY HH:mm');
          break;
        case citasEstadosConstants.NO_ACUDE:
          mensaje = 'No ha acudido a su cita del día: '+
          this.datePipe.transform(cita.fecha, 'dd/MM/YYYY HH:mm');
          break;
        default:
          mensaje = 'El estado de su cita ha pasado a '+ cita.estado;
          break;
      }
      return mensaje;
    }

  public envioGeneral( notificacion: Notificacion) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/push/envioGeneral`, notificacion, {headers})
        .subscribe( resp => {
          resolve(true);
        });
    });
  }

  public envioProgramado( notificacion: Notificacion) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/push/programar`, notificacion, {headers})
        .subscribe( resp => {
          resolve(true);
        });
    });
  }

  private envioSimple( notificacion: Notificacion) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/push/envio`, notificacion, {headers})
        .subscribe( resp => {
          resolve(true);
        });
    });
  }
}
