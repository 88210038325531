import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UsuarioService } from './usuario.service';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class WhatsappService {


  constructor( private http: HttpClient,
    private usuarioService: UsuarioService) { }

  public enviorRecordatorioCitaHoy(telefono: string, hora: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/whatsapp/sendHoy`, {telefono, hora}, {headers})
        .subscribe( resp => {
          resolve(true);
        });
    });
  }

  public enviorRecordatorioCitaTomorrow(telefono: string, hora: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/whatsapp/sendTomorrow`, {telefono, hora}, {headers})
        .subscribe( resp => {
          resolve(true);
        });
    });
  }

  public enviorRecordatorioSolicitud(telefono: string, texto: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/whatsapp/sendRecordatorio`, {telefono, texto}, {headers})
        .subscribe( resp => {
          resolve(true);
        });
    });
  }
}
