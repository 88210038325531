import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Cliente } from '../interfaces/interfaces';
import { UsuarioService } from './usuario.service';
import { UtilsService } from './utils.service';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class ClienteService {


  constructor( private http: HttpClient,
              private utilsService: UtilsService,
              private usuarioService: UsuarioService) { }

  public getClientes() {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cliente[]>(`${ URL }/cliente`, {headers});
  }

  public buscarClientes(texto: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post<Cliente[]>(`${ URL }/cliente/buscar`, {texto}, {headers});
  }

  public addOrUpdateCliente(cliente: Cliente) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    if (cliente._id && cliente._id !== '') {
      return this.actualizarCliente(cliente, headers);
    } else {
      return this.crearCliente(cliente, headers);
    }
  }

  private crearCliente( cliente: Cliente, headers: HttpHeaders) {
    //return new Promise( resolve => {
      return this.http.post(`${ URL }/cliente/create`, cliente, {headers});
        // .subscribe( resp => {
        //   console.log("RESPUESTA", resp);
        //   resolve(true);
        // });
    //});
  }

  private actualizarCliente( cliente: Cliente, headers: HttpHeaders) {
    // return new Promise( resolve => {
      return this.http.post(`${ URL }/cliente/update`, cliente, {headers});
    //     .subscribe( resp => {
    //       resolve(true);
    //   });
    // });
  }

  public resetCliente( cliente: Cliente) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    cliente.cambioPassword = true;
    return new Promise<boolean>( resolve => {
      this.http.post(`${ URL }/cliente/update`, cliente, {headers})
        .subscribe( resp => {
          resolve(resp['ok']);
      });
    });
  }


}
