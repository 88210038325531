import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../pipes/pipes.module';
import { AvatarSelectorComponent } from './avatar-selector/avatar-selector.component';
import { MapaComponent } from './mapa/mapa.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { ClientResumeComponent } from './client-resume/client-resume.component';
import { ModalCitasComponent } from './modal-citas/modal-citas.component';
import { CitaComponent } from './cita/cita.component';
import { ModalPresupuestosComponent } from './modal-presupuestos/modal-presupuestos.component';
import { PresupuestoComponent } from './presupuesto/presupuesto.component';
import { ModalFichaClienteComponent } from './modal-ficha-cliente/modal-ficha-cliente.component';
import { FichaClienteComponent } from './ficha-cliente/ficha-cliente.component';
import { BonoComponent } from './bono/bono.component';
import { ModalFormularioCitaComponent } from './modal-formulario-cita/modal-formulario-cita.component';
import { ModalGestionBonoComponent } from './modal-gestion-bono/modal-gestion-bono.component';
import { ModalZonasComponent } from './modal-zonas/modal-zonas.component';
import { PromocionComponent } from './promocion/promocion.component';
import { ModalTransferirBonoComponent } from './modal-transferir-bono/modal-transferir-bono.component';
import { FormCitaComponent } from './form-cita/form-cita.component';
import { ModalProgramarSolicitudComponent } from './modal-programar-solicitud/modal-programar-solicitud.component';
import { RecordatorioComponent } from './recordatorio/recordatorio.component';
import { AnotacionComponent } from './anotacion/anotacion.component';
import { ModalCrearAnotacionComponent } from './modal-crear-anotacion/modal-crear-anotacion.component';
import { ModalNotificacionesComponent } from './modal-notificaciones/modal-notificaciones.component';

@NgModule({
  entryComponents:[
    ModalCitasComponent,
    ModalPresupuestosComponent,
    ModalFichaClienteComponent,
    ModalFormularioCitaComponent,
    ModalGestionBonoComponent,
    ModalZonasComponent,
    PromocionComponent,
    ModalTransferirBonoComponent,
    ModalProgramarSolicitudComponent,
    ModalCrearAnotacionComponent,
    ModalNotificacionesComponent
  ],
  declarations: [
    AvatarSelectorComponent,
    MapaComponent,
    HeaderComponent,
    MenuComponent,
    ClientResumeComponent,
    ModalCitasComponent,
    CitaComponent,
    ModalPresupuestosComponent,
    PresupuestoComponent,
    ModalFichaClienteComponent,
    FichaClienteComponent,
    BonoComponent,
    ModalFormularioCitaComponent,
    ModalGestionBonoComponent,
    ModalZonasComponent,
    PromocionComponent,
    ModalTransferirBonoComponent,
    FormCitaComponent,
    ModalProgramarSolicitudComponent,
    ModalCrearAnotacionComponent,
    RecordatorioComponent,
    AnotacionComponent,
    ModalNotificacionesComponent
  ],
  exports: [
    AvatarSelectorComponent,
    HeaderComponent,
    MenuComponent,
    ClientResumeComponent,
    PromocionComponent,
    CitaComponent,
    RecordatorioComponent,
    AnotacionComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ]
})
export class ComponentsModule { }
