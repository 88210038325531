import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController, LoadingController } from '@ionic/angular';
import { Cliente, Notificacion, Recordatorio } from 'src/app/interfaces/interfaces';
import { AnotacionService } from 'src/app/services/anotacion.service';
import { PushService } from 'src/app/services/push.service';
import { RecordatorioService } from 'src/app/services/recordatorio.service';

@Component({
  selector: 'app-modal-crear-anotacion',
  templateUrl: './modal-crear-anotacion.component.html',
  styleUrls: ['./modal-crear-anotacion.component.scss'],
})
export class ModalCrearAnotacionComponent implements OnInit {

  @Input()
  public cliente: Cliente;

  public fecha = '';
  public texto = '';

  loading: any;

  public titulo: string = '';

  constructor(private modalCtrl: ModalController,
    private anotacionService: AnotacionService,
    private loadingCntrl: LoadingController) { }

  ngOnInit() {
  }

  cerrarModal() {
    this.modalCtrl.dismiss();
  }

  async presentLoading() {
    this.loading = await this.loadingCntrl.create({
      message: 'Cargando'
    });
    return await this.loading.present();
  }

  public enviar(fAnotacion: NgForm) {
    if(fAnotacion.invalid) {return;}
    const anotacion: Notificacion = {
      texto: this.texto,
      cliente: this.cliente._id,
      fecha: new Date().toString(),
    }
    this.anotacionService.crearAnotacion(anotacion).then(() => {
      this.cerrarModal();
    });
  }

}
