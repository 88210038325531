import { Component, Input, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { Bono, Cita, Cliente } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-modal-formulario-cita',
  templateUrl: './modal-formulario-cita.component.html',
  styleUrls: ['./modal-formulario-cita.component.scss'],
})
export class ModalFormularioCitaComponent implements OnInit {

  @Input()
  public cita: Cita;

  @Input()
  public cliente: Cliente;


  loading: any;

  constructor(private modalCtrl: ModalController,
    private loadingCntrl: LoadingController) { }

  ngOnInit() {
  }

  cerrarModal() {
    this.modalCtrl.dismiss();
  }

  async presentLoading() {
    this.loading = await this.loadingCntrl.create({
      message: 'Cargando'
    });
    return await this.loading.present();
  }


}
