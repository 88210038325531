import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Cita, Promocion } from '../interfaces/interfaces';
import { UsuarioService } from './usuario.service';
import { FileTransferObject, FileTransfer, FileUploadOptions} from '@awesome-cordova-plugins/file-transfer/ngx';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class PromocionService {


  constructor( private http: HttpClient,
              private fileTransfer: FileTransfer,
              private usuarioService: UsuarioService) { }


  public addOrUpdatePromocion(promo: Promocion, formData: FormData) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    formData.append('datos', JSON.stringify(promo));
    if (promo._id && promo._id !== '') {
      return this.actualizarPromo(formData, headers);
    } else {
      return this.crearPromo(formData, headers);
    }
  }

  private crearPromo( formData: FormData, headers: HttpHeaders) {
    return new Promise( resolve => {
      this.http.post(`${ URL }/promocion/create`, formData, {headers})
        .subscribe( resp => {
          resolve(resp['ok']);
        });
    });
  }

  private actualizarPromo( formData: FormData, headers: HttpHeaders) {
    return new Promise( resolve => {
      this.http.post(`${ URL }/promocion/update`, formData, {headers})
        .subscribe( resp => {
          resolve(resp['ok']);
      });
    });
  }

  public cambiarEstado( promo: Promocion) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    promo.activa = !promo.activa;
    return new Promise( resolve => {
      this.http.post(`${ URL }/promocion/update`, promo, {headers})
        .subscribe( resp => {
          resolve(resp['ok']);
      });
    });
  }

  public subirImagen( formData ) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    this.http.post(`${ URL }/promocion/uploadImage`, formData, {headers}).subscribe((response) => {
      console.log(response);
    });
  }

  public getPromociones() {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/promocion/`, {headers});
  }

  public eliminarPromocion(promoId: string) {
    return new Promise( resolve => {
      const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
      });
      return this.http.get<Cita>(`${ URL }/promocion/delete/${ promoId }`, {headers}).subscribe(resp =>{
        return resolve(true);
      }); 
    });

  }
}
