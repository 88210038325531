import { Component, Input, OnInit } from '@angular/core';
import { ModalController, LoadingController, ActionSheetController, AlertController } from '@ionic/angular';
import { Bono, Cita, Cliente, Zona } from 'src/app/interfaces/interfaces';
import { BonoService } from 'src/app/services/bono.service';
import { CitaService } from 'src/app/services/cita.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { BonoConstants } from '../bono/bono.constant';

@Component({
  selector: 'app-modal-transferir-bono',
  templateUrl: './modal-transferir-bono.component.html',
  styleUrls: ['./modal-transferir-bono.component.scss'],
})
export class ModalTransferirBonoComponent implements OnInit {

  @Input()
  public bono:Bono;

  public clientes: Cliente[] = [];

  constructor(private modalCtrl: ModalController,
    private clienteService: ClienteService,
    private alertController: AlertController,
    private bonoService: BonoService,
    private modalController: ModalController,
    public actionSheetController: ActionSheetController) { }

  ngOnInit() {
    this.initClientes();
  }

  cerrarModal() {
    this.modalCtrl.dismiss();
  }

  public buscar(event) {
    let busqueda = event.detail.value;
    if (busqueda && busqueda !== '') {
      this.clienteService.buscarClientes(event.detail.value).subscribe(resp => {
        this.clientes = resp['clientes'];
      })
    } else {
      this.initClientes();
    }
  }

  private initClientes() {
    this.clienteService.getClientes().subscribe(clientes => {
      this.clientes = clientes['clientes'];
    });
  }

  public async preguntarCompartirBono(cliente) {
    const alert = await this.alertController.create({
      header: '¿Estas seguro de compartir el Bono con ' +cliente.nombre+ '?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'NO',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'SI',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.compartirBono(cliente);
          }
        },
      ],
    });

    await alert.present();
  }

  private compartirBono(cliente: Cliente) {
    // creamos nuevo bono en persona de destino
    let nuevoBono: Bono = {
      _id: '',
      cliente: cliente._id,
      compartidoDe: this.bono.cliente,
      sesiones: this.bono.sesiones_pendientes,
      sesiones_pendientes: this.bono.sesiones_pendientes,
      tipo: this.bono.tipo,
      estado: this.bono.estado
    }
    if(nuevoBono.tipo === '1') {
      nuevoBono.zonas = this.bono.zonas;
    }
    this.bonoService.addOrUpdateBono(nuevoBono);

    // modificamos el antiguo bono
    this.bono.compartidoA = cliente._id;
    this.bono.sesiones_pendientes = 0;
    if(this.bono.tipo === '1') {
      this.bono.zonas = this.cargarZonas(0);
    }
    this.bono.estado = BonoConstants.FINALIZADO;
    this.bonoService.actualizarBonoSimple(this.bono);
    this.modalController.dismiss();
  }

  private cargarZonas(sesiones: number): string {
    let zonas: Zona[] = [];
    const zonasSelected = JSON.parse(this.bono.zonas);
    zonasSelected.forEach(zona => {
      zonas.push({nombre:zona.nombre, sesiones:sesiones});
    });
    return JSON.stringify(zonas);
  }
}
