import { Component, OnInit, Input } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input()
  showBackButton = false;

  public isMobile = this.deviceDetector.isMobile();

  constructor( private usuarioService: UsuarioService,
    private navCtrl: NavController,
    private deviceDetector: DeviceDetectorService) { }

  ngOnInit() {}

  logout() {
    this.usuarioService.logout();
  }

  public goToUrl(url: string) {
    this.navCtrl.navigateRoot( '/' + url);
  }
}
