import { Component, Input, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { Cita, Zona } from 'src/app/interfaces/interfaces';
import { citasEstadosConstants } from '../cita/citas.estados.constants';

@Component({
  selector: 'app-modal-zonas',
  templateUrl: './modal-zonas.component.html',
  styleUrls: ['./modal-zonas.component.scss'],
})
export class ModalZonasComponent implements OnInit {

  @Input()
  public zonas: string = '';

  public zonasArr: Zona[]= [];
  
  @Input()
  public zonasSelected: string[];

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.zonasArr = JSON.parse(this.zonas);
  }

  cerrarModal(evento: string) {
    let objetoRes = {};
    if (evento === 'enviar') {
      objetoRes = {zonas: this.zonasSelected, enviar: true}
    } else {
      objetoRes = {zonas: this.zonasSelected, enviar: false}
    }
    this.modalCtrl.dismiss(objetoRes);
  }


}
