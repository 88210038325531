import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Presupuesto, Servicio } from '../interfaces/interfaces';
import { UsuarioService } from './usuario.service';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class PresupuestosService {

  constructor( private http: HttpClient,
    private usuarioService: UsuarioService) { }

  public getPresupuestosByEstado(estado: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post<Presupuesto[]>(`${ URL }/presupuesto/estado`, {estado: estado}, {headers});
  }

  public actualizar(presupuesto: Presupuesto) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post(`${ URL }/presupuesto/update`, presupuesto, {headers});
  }

  }
