import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Anotacion, Recordatorio } from 'src/app/interfaces/interfaces';
import { AnotacionService } from 'src/app/services/anotacion.service';
import { RecordatorioService } from 'src/app/services/recordatorio.service';

@Component({
  selector: 'app-anotacion',
  templateUrl: './anotacion.component.html',
  styleUrls: ['./anotacion.component.scss'],
})
export class AnotacionComponent implements OnInit {

  @Input()
  public anotacion:Anotacion;

  @Output()
  returEvento = new EventEmitter<any>();

  constructor(public actionSheetController: ActionSheetController,
    private anotacionService: AnotacionService) { }

  ngOnInit() {}

  public async menu() {

    const actionSheet = await this.actionSheetController.create({
      header: 'ANOTACION',
      cssClass: 'my-custom-class',
      buttons:[
      {
        text: 'Eliminar',
        icon: 'trash',
        handler: () => {
          this.eliminar();
        },
      }]
    });
    await actionSheet.present();

  }

  private eliminar() {
    this.anotacionService.eliminar(this.anotacion._id).then(()=> {
      this.returEvento.emit(true);
    })
  }

}
