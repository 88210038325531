import { Component, Input, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { Cita } from 'src/app/interfaces/interfaces';
import { CitaService } from 'src/app/services/cita.service';
import { citasEstadosConstants } from '../cita/citas.estados.constants';

@Component({
  selector: 'app-modal-citas',
  templateUrl: './modal-citas.component.html',
  styleUrls: ['./modal-citas.component.scss'],
})
export class ModalCitasComponent implements OnInit {

  @Input()
  public tipo: string = '';

  loading: any;

  public titulo: string = '';

  public citas: Cita[] = [];

  constructor(private modalCtrl: ModalController,
    private citasService: CitaService,
    private loadingCntrl: LoadingController) { }

  ngOnInit() {
    this.cargarCitas();
    this.inicializar();
  }

  cerrarModal() {
    this.modalCtrl.dismiss();
  }

  async presentLoading() {
    this.loading = await this.loadingCntrl.create({
      message: 'Cargando'
    });
    return await this.loading.present();
  }

  public cargarCitas(){
    this.citasService.getCitasByEstado(this.tipo).subscribe(resp => {
      this.citas = resp['citas'];
    });
  }

  private inicializar(): void {
    switch (this.tipo) {
      case citasEstadosConstants.SOLICITUD:
        this.titulo = 'Citas Solicitadas';
        break;
      case citasEstadosConstants.CAMBIO:
        this.titulo = 'Solicitudes de Cambio';
        break;
      case citasEstadosConstants.PTE_VALIDAR:
        this.titulo = 'Pendientes de Validar';
        break;
      case citasEstadosConstants.NO_ACUDE:
        this.titulo = 'No Acuden';
        break;      
      default:
        break;
    }
  }


}
