import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IonModal, ModalController, ToastController } from '@ionic/angular';
import { Bono, Cita, Cliente, Servicio, Usuario, Zona } from 'src/app/interfaces/interfaces';
import { BonoService } from 'src/app/services/bono.service';
import { CitaService } from 'src/app/services/cita.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { PushService } from 'src/app/services/push.service';
import { ServiciosService } from 'src/app/services/servicios.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { BonoConstants } from '../bono/bono.constant';
import { citasEstadosConstants } from '../cita/citas.estados.constants';
import { ServiciosConstants } from '../cita/servicios.constant';
import { ModalFichaClienteComponent } from '../modal-ficha-cliente/modal-ficha-cliente.component';

@Component({
  selector: 'app-form-cita',
  templateUrl: './form-cita.component.html',
  styleUrls: ['./form-cita.component.scss'],
})
export class FormCitaComponent implements OnInit {

  @Input()
  public cita: Cita = {};

  @Input()
  public cliente: Cliente;

  @ViewChild(IonModal) modal: IonModal;

  public editMode = false;
  public crearCitaDesdeBono = false;

  public areas = ServiciosConstants.areas;
  public servicios: Servicio[] = [];
  public bonosCliente: Bono[] = [];
  public zonas: string[];
  public empleados: Usuario[];

  public areaSelect: number;
  public servicioSelect: Servicio;
  public bonoSelect: Bono;
  public idBonoSelct: string;
  public zonasSelect: string[] = [];
  public revision = false;
  public fechaSelect = '';
  public empleadoSelect: Usuario;
  

  public showCliente = false;
  public showFecha = false;
  public showBonos = false;
  public showZonas = false;
  public showRevision = false;

  public notificacionEnviada = false;

  public clientes: Cliente[] = [];
  
  constructor(
    private serviService: ServiciosService,
    private bonoService: BonoService,
    private citaService: CitaService,
    private modalCntrl: ModalController,
    private pushService: PushService,
    private clienteService: ClienteService,
    private toastController: ToastController,
    private datePipe: DatePipe,
    private usuarioService: UsuarioService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    if(this.cita.empleado) {
      this.empleadoSelect = this.cita.empleado as Usuario;
    } else {
      this.empleadoSelect = {_id:''};
    }
    this.usuarioService.getEmpleados().subscribe(resp => {
      this.empleados = resp['empleados'];
    })
    if(this.cita && this.cita._id && this.cita._id !== ''){
      this.editMode = true;
      this.showFecha = true;
      this.revision = this.cita.revision;
      this.cargarEdicion();
    }
    if (this.cita && this.cita.bono && !this.editMode) {
      this.crearCitaDesdeBono = true;
      this.showRevision = true;
      this.showFecha = true;
      if((this.cita.bono as Bono).tipo === '1') {
        this.zonas = this.cargarZonasSelecBono(this.cita.bono);
        this.showZonas = true;
      }
    }
    if(!this.cliente) {
      this.showCliente = true;
    }
    if(this.cita.fecha) {
      this.fechaSelect = this.datePipe.transform(new Date(this.cita.fecha), 'yyyy-MM-ddTHH:mm:ss');
    }

  }

  public selectedArea() {
    this.servicioSelect = {_id:''};
    this.servicios = [];
    this.initForm();
    this.serviService.getServiciosByArea(this.areaSelect).subscribe(resp => {
      this.servicios = resp['servicios'];
    });
  }

  public selectedServicio() {
    this.initForm();
    this.bonoSelect = null;
    this.zonasSelect = [];
    this.servicioSelect = this.servicios.find(s => s._id === this.servicioSelect._id);
    if(this.servicioSelect.tipoBono && this.servicioSelect.tipoBono !== '0') {
      if (this.servicioSelect.tipoBono === '1') {
        this.showRevision = true;
      }
      this.chargeBonos();
    } else {
      if (this.servicioSelect.nombre === 'Cera') {
        this.zonas = ServiciosConstants.zonasCera;
        this.showZonas = true;
      } else {
        this.showFecha = true;
      }
    }
  }

  public selectedEmpleado() {
    this.empleadoSelect = this.empleados.find(e => e._id === this.empleadoSelect._id);
  }

  public changeRevision() {
    if(!this.revision) {
      if(this.crearCitaDesdeBono) {
        this.showZonas = (this.cita.bono as Bono).tipo === '1' ? true : false;
      } else {
        this.showFecha = false;
        this.chargeBonos();
      }
    } else {
      if(this.crearCitaDesdeBono) {
        this.showZonas = false;
      } else {
        this.bonoSelect = null;
        this.showBonos = false;
        this.showFecha = true;
      }
    }
  }

  public selectedBono() {
    this.showZonas = false;
    this.showFecha = false;
    this.zonasSelect = [];
    this.bonoSelect = this.bonosCliente.find(b => b._id === this.idBonoSelct);
    if(!this.bonoSelect && this.servicioSelect.tipoBono === '1') {
      this.zonas = BonoConstants.zonas;
      this.showZonas = true
    } else {
      if(this.bonoSelect && this.servicioSelect.tipoBono === '1') {
        this.showZonas = true;
        this.zonas = this.cargarZonasSelecBono(this.bonoSelect);
        this.zonasSelect.forEach(zs => {
          if(!this.zonas.find(z=>z === zs)){
            this.zonas.push(zs);
          }
        });
      } else {
        this.showFecha = true;
      }
    }
  }

  public selectedZonas() {
    this.showFecha = true;
  }

  public getNombreBono(bono) {
    if(bono.tipo === '1') {
      const zonarray: Zona[] = JSON.parse(bono.zonas);
      let zonasStr: string[] = [];
      zonarray.forEach(z => {
        zonasStr.push(z.nombre +': '+ z.sesiones);
      })
      return zonasStr.toString();
    } else {
      return BonoConstants.cargarNombreBono(bono.tipo) + '=> Sesiones pendientes: ' +bono.sesiones_pendientes;
    }
  }

  public guardar(fCita: NgForm) {
    if (fCita.invalid){return;}
    // if(!this.editMode && !this.crearCitaDesdeBono) {
    //   this.crear();
    // } else {
      if(this.crearCitaDesdeBono) {
        this.crearDesdeBono();
      }else {
        this.crear();
      }
    // }
  }

  public crear() {
    this.cita.empleado = this.empleadoSelect._id;
    this.cita.cliente = this.cliente._id;
    this.cita.servicio = this.servicioSelect._id;
    this.cita.estado = citasEstadosConstants.PROGRAMADA;
    this.cita.revision = this.revision;
    this.cita.fecha = this.fechaSelect;
    if (this.zonasSelect && this.zonasSelect.length > 0) {
      this.cita.zonas = JSON.stringify(this.zonasSelect);
    } else {
      this.cita.zonas = '';
    }
    if (this.bonoSelect) {
      this.cita.bono = this.bonoSelect._id;
      // if(this.bonoSelect.tipo === '1') {
      //   this.cita.zonas = JSON.stringify(this.cargarZonasSelecBono(this.bonoSelect));
      // }
      this.citaService.addOrUpdateCita(this.cita, this.bonoSelect);
    } else {
      this.bonoSelect = null;
      this.cita.bono = null;
      if(this.cita._id && this.cita._id !== ''){
        this.actualizar();
      } else {
        this.citaService.crearCitaSimple(this.cita);
      }
    }
    if(!this.notificacionEnviada) {
      this.pushService.enviarPushSimple(this.cita.estado, 'NUEVA CITA', this.cliente, this.cita);
    }
    this.notificacionEnviada = true;
    this.presentToast();
    //this.modalCntrl.dismiss();
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Se ha guardado la cita correctamente',
      position: 'middle',
      duration: 3000,
      color: 'success'
    });
    toast.present();
  }

  public crearDesdeBono() {
    this.cita.estado = citasEstadosConstants.PROGRAMADA;
    this.cita.revision = this.revision;
    this.cita.fecha = this.fechaSelect;
    this.cita.empleado = this.empleadoSelect._id;

    if((this.cita.bono as Bono).tipo === '1' && !this.cita.revision) {
      // this.cita.zonas = JSON.stringify(this.cargarZonasSelecBono(this.cita.bono as Bono));
      this.cita.zonas = JSON.stringify(this.zonasSelect);
    }
    this.citaService.addOrUpdateCita(this.cita, this.cita.bono as Bono);

    this.pushService.enviarPushSimple(this.cita.estado, 'NUEVA CITA', this.cliente, this.cita)
    this.modalCntrl.dismiss();
  }

  public actualizar() {
    this.cita.estado = citasEstadosConstants.PROGRAMADA;
    if(this.cita.bono) {
      this.citaService.addOrUpdateCita(this.cita, this.cita.bono as Bono);
    } else {
      this.citaService.actualizarCitaSimple(this.cita);
    }
    this.pushService.enviarPushSimple(this.cita.estado, '', this.cliente, this.cita);
    this.modalCntrl.dismiss();
  }

  public cancelar() {
    if(this.cita.estado === citasEstadosConstants.SOLICITUD) {
      
    }
    this.modalCntrl.dismiss();
  }

  private initForm() {
    this.showBonos = false;
    this.showZonas = false;
    this.showFecha = false;
    this.showRevision = false;
  }

  public cargarZonasSelecBono(bono): string[] {
    const zonaRet: string[] = [];
    if(bono.tipo === '1') {
      const zonasSelectedObj: Zona[] = JSON.parse(bono.zonas);
      zonasSelectedObj.forEach(zona => {
        if(zona.sesiones > 0) {
          zonaRet.push(zona.nombre);
        }
      });
    }
    return zonaRet;
  }

  public abrirBuscadorClientes() {
    if(this.showCliente) {
      this.modal.present();
    }
  }

  public buscar(event) {
    let busqueda = event.detail.value;
    if (busqueda && busqueda !== '') {
      this.clienteService.buscarClientes(event.detail.value).subscribe(resp => {
        this.clientes = resp['clientes'];
      })
    } else {
      this.clientes = [];
    }
  }

  public seleccionarCliente(cliente: Cliente) {
    this.cliente = cliente;
    this.modal.dismiss();
  }

  async nuevoCliente() {
    const modal = await this.modalCtrl.create({
      component: ModalFichaClienteComponent,
      componentProps: {
        cliente: null,
        cssClass: 'fullscreen',
        desdeAgenda: true
      }
    });
    return await modal.present();
  }

  private chargeBonos() {
    this.bonoService.getBonosActivosByClienteTipo(this.cliente._id, this.servicioSelect.tipoBono).subscribe(resp => {
      this.bonosCliente = resp['bonos'];
      if(this.editMode) {
        if(this.cita.bono) {
          if(!this.bonosCliente.find(b => b._id === (this.cita.bono as Bono)._id)) {
            this.bonosCliente.push(this.cita.bono as Bono);
          }
        }
      }
      if(this.bonosCliente && this.bonosCliente.length !== 0) {
        this.showBonos = true;
      } else {
        if(this.servicioSelect.tipoBono === '1') {
          this.zonas = BonoConstants.zonas;
          this.showZonas = true;
        } else {
          this.showFecha = true;
        }
      }
    })
  }

  private cargarEdicion() {
    this.servicioSelect = this.cita.servicio as Servicio;
    this.areaSelect = this.areas.find(a => a.id === this.servicioSelect.grupo)?.id;
    this.serviService.getServiciosByArea(this.areaSelect).subscribe(resp => {
      this.servicios = resp['servicios'];
      if(this.servicioSelect.tipoBono && this.servicioSelect.tipoBono !== '0') {
        if (this.servicioSelect.tipoBono === '1') {
          this.showRevision = true;
          this.revision === this.cita.revision;
        }
        if(!this.cita.revision) {
          this.bonoSelect = this.cita.bono as Bono;
          this.idBonoSelct = this.bonoSelect ? this.bonoSelect._id : 'null';
          this.chargeBonos();
        }
      }
      if(this.cita.zonas && this.cita.zonas !== '') {
        this.zonasSelect = JSON.parse(this.cita.zonas);
        this.showZonas = true;
        if (this.servicioSelect.nombre === 'Cera') {
          this.zonas = ServiciosConstants.zonasCera;
        }else if(this.bonoSelect) {
          this.zonas = this.cargarZonasSelecBono(this.bonoSelect);
          this.zonasSelect.forEach(zs => {
            if(!this.zonas.find(z=>z === zs)){
              this.zonas.push(zs);
            }
          });
        } else {
          this.zonas = BonoConstants.zonas;
        }
      }
    });
  }
}
