import { Component, Input, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { Presupuesto } from 'src/app/interfaces/interfaces';
import { PresupuestosService } from 'src/app/services/presupuestos.service';
import { PresupuestosEstadosConstants } from './presupuestos.estados.constants';

@Component({
  selector: 'app-modal-presupuestos',
  templateUrl: './modal-presupuestos.component.html',
  styleUrls: ['./modal-presupuestos.component.scss'],
})
export class ModalPresupuestosComponent implements OnInit {

  public numSolicitados = 0;
  public numEnviados = 0;
  public numAceptados = 0;
  public numRechazados = 0;

  public presupuestosSolicitados: Presupuesto[];
  public presupuestosEnviados: Presupuesto[];
  public presupuestosAceptados: Presupuesto[];
  public presupuestosRechazados: Presupuesto[];

  constructor(private modalCtrl: ModalController,
    private presupuestoService: PresupuestosService) { }

  ngOnInit() {
    this.cargarPresupuestos();
  }

  private cargarPresupuestos() {
    this.presupuestoService.getPresupuestosByEstado(PresupuestosEstadosConstants.SOLICITUD).subscribe(resp => {
      this.presupuestosSolicitados = resp['presupuestos'];
      this.numSolicitados = this.presupuestosSolicitados.length;
    })
    this.presupuestoService.getPresupuestosByEstado(PresupuestosEstadosConstants.ENVIADO).subscribe(resp => {
      this.presupuestosEnviados = resp['presupuestos'];
      this.numEnviados = this.presupuestosEnviados.length;
    })
    this.presupuestoService.getPresupuestosByEstado(PresupuestosEstadosConstants.ACEPTADO).subscribe(resp => {
      this.presupuestosAceptados = resp['presupuestos'];
      this.numAceptados = this.presupuestosAceptados.length;
    })
    this.presupuestoService.getPresupuestosByEstado(PresupuestosEstadosConstants.RECHAZADO).subscribe(resp => {
      this.presupuestosRechazados = resp['presupuestos'];
      this.numRechazados = this.presupuestosRechazados.length;
    })
  }

  public cerrarModal() {
    this.modalCtrl.dismiss();
  }

}
