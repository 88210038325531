import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Anotacion, Recordatorio, Servicio } from '../interfaces/interfaces';
import { UsuarioService } from './usuario.service';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class AnotacionService {

  constructor( private http: HttpClient,
    private usuarioService: UsuarioService) { }

  public getAnotacionesByCliente(idCliente: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Servicio[]>(`${ URL }/anotacion/${ idCliente }`, {headers});
  }

  public crearAnotacion( anotacion: Anotacion) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise<boolean>( resolve => {
      this.http.post(`${ URL }/anotacion/create`, anotacion, {headers})
        .subscribe( resp => {
          if(resp['ok']) {
            resolve(true);
          }
          resolve(false);
        });
    });
  }

  public eliminar(anotacionId: string) {
    return new Promise( resolve => {
      const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
      });
      return this.http.get<Recordatorio>(`${ URL }/anotacion/delete/${ anotacionId }`, {headers}).subscribe(resp =>{
        return resolve(true);
      }); 
    });
  }

  public actualizarAnotacion( anotacion: Anotacion) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise<boolean>( resolve => {
      this.http.post(`${ URL }/anotacion/update`, anotacion, {headers})
        .subscribe( resp => {
          resolve(true);
      });
    });
  }

}