import { Component, Input, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { Bono, Cita, Cliente } from 'src/app/interfaces/interfaces';
import { BonoService } from 'src/app/services/bono.service';
import { CitaService } from 'src/app/services/cita.service';
import { BonoConstants } from '../bono/bono.constant';

@Component({
  selector: 'app-modal-gestion-bono',
  templateUrl: './modal-gestion-bono.component.html',
  styleUrls: ['./modal-gestion-bono.component.scss'],
})
export class ModalGestionBonoComponent implements OnInit {

  @Input()
  public bono: Bono;

  @Input()
  public cliente: Cliente;

  @Input()
  public citas: Cita[];
  
  @Input()
  public showClienteBono: boolean = true;

  public nombreBono: string = '';

  loading: any;

  constructor(private modalCtrl: ModalController,
    private bonoService: BonoService,
    private citaService: CitaService,
    private loadingCntrl: LoadingController) { }

  ngOnInit() {
    this.nombreBono = BonoConstants.cargarNombreBono(this.bono.tipo);
  }

  cerrarModal() {
    this.modalCtrl.dismiss();
  }

  async presentLoading() {
    this.loading = await this.loadingCntrl.create({
      message: 'Cargando'
    });
    return await this.loading.present();
  }

  public addCita(): void {
    if (!this.citas) {
      this.citas = [];
    }
    this.citas.unshift({bono: this.bono._id})
  }

  public eventosDesdeCitas() {
    this.citas = [];
    if(this.bono.tipo === '1') {
      setTimeout(() => {
        this.bonoService.getBonoById(this.bono._id).subscribe(resp => {
          this.bono = resp['bono'];
        })
      }, 500);
    }
    this.citaService.getCitasByBono(this.bono._id).subscribe(resp => {
      this.citas = resp['citas'];
    });
  }

}
