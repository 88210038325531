export class citasEstadosConstants {
    static readonly SOLICITUD = 'solicitud';
    static readonly PROGRAMADA = 'programada';
    static readonly ACUDIDA = 'acudida';
    static readonly NO_ACUDE = 'no acudida';
    static readonly CAMBIO = 'cambio';
    static readonly CANCELADA = 'cancelada';
    static readonly PTE_VALIDAR = 'pendiente validar';
    static readonly PTE_PROPUESTA = 'pendiente propuesta';
    static readonly CANCELADA_RESTA = 'cancelada resta sesion';
    static readonly CANCELADA_GENERAL = 'cancelada general';

    static readonly arrayEstadosCitas: string[] = ['programada', 'cancelada', 'pendiente validar'];

}