import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { Bono, Cita, Cliente, Servicio, tipoBono, Zona } from 'src/app/interfaces/interfaces';
import { BonoService } from 'src/app/services/bono.service';
import { CitaService } from 'src/app/services/cita.service';
import { ServiciosService } from 'src/app/services/servicios.service';
import { citasEstadosConstants } from '../cita/citas.estados.constants';
import { ModalFormularioCitaComponent } from '../modal-formulario-cita/modal-formulario-cita.component';
import { ModalGestionBonoComponent } from '../modal-gestion-bono/modal-gestion-bono.component';
import { ModalTransferirBonoComponent } from '../modal-transferir-bono/modal-transferir-bono.component';
import { BonoConstants } from './bono.constant';

@Component({
  selector: 'app-bono',
  templateUrl: './bono.component.html',
  styleUrls: ['./bono.component.scss'],
})
export class BonoComponent implements OnInit, OnChanges {

  @Input()
  bono: Bono;

  @Input()
  cliente: Cliente;

  @Input()
  activeMenu: boolean = true;

  @Input()
  showListCita: boolean = true;

  @Output()
  returEvento = new EventEmitter<string>();


  public editMode: boolean = false;

  public zonas: string[];

  public tipoBonos: tipoBono[];

  public zonasSelected: string[] = [];

  public zonasInicioSelectedObj: Zona[] = [];
  public zonasSelectedObj: Zona[] = [];

  public tipoBono: number;

  public nombreBono: string = '';

  public sesionesPendientes: number = 0;

  public citas: Cita[] = [];

  public servicio: Servicio;

  constructor(public actionSheetController: ActionSheetController,
    private modalCtrl: ModalController,
    private citaService: CitaService,
    private serviServicio: ServiciosService,
    private bonoService: BonoService) { }


  ngOnInit() {
    this.zonas = BonoConstants.zonas;
    debugger;
    this.tipoBonos = BonoConstants.tipoBonos;
    if (!this.bono._id) {
      this.editMode = true;
    } else {
      this.cargarNombreBono();
      /*if (this.bono.tipo === '1') {
        this.descargarZonas();
      }*/
      this.updateCitas();
      if(this.bono.tipo === '1') {
        this.descargarZonas();
      }
      // this.calcularSesionesPendientes();
    }
    this.updateServicio();

  }

  ngOnChanges(changes: SimpleChanges): void {
    /*if (changes.bono.currentValue !== changes.bono.previousValue) {
      this.zonas = BonoConstants.zonas;
      this.tipoBonos = BonoConstants.tipoBonos;
      if (!this.bono._id) {
        this.editMode = true;
      } else {
        this.cargarNombreBono();
        if (this.bono.tipo === '1') {
          this.descargarZonas();
        }
        this.updateCitas();
        // this.calcularSesionesPendientes();
      }
    }*/
  }
  


  /*private calcularSesionesPendientes() {
    this.bonoService.getSesionesPendientesByBono(this.bono).subscribe(resp => {
      this.sesionesPendientes = this.bono.sesiones - resp['citas'];
    });
  }*/

  private descargarZonas() {
    this.zonasInicioSelectedObj = JSON.parse(this.bono.zonas_inicio);
    this.zonasSelectedObj = JSON.parse(this.bono.zonas);
    this.zonasInicioSelectedObj.forEach(zona => {
      this.zonasSelected.push(zona.nombre);
    })
  }

  private cargarNombreBono() {
    this.nombreBono = BonoConstants.cargarNombreBono(this.bono.tipo);
  }
  
  async presentActionSheet() {
    let buttons = [
    ];
    if(!this.bono.compartidoA) {
      buttons.push(
      {
        text: 'Gestionar citas',
        icon: 'calendar',
        handler: () => {
          this.gestionarBono();
        },
      },
      {
        text: 'Editar Bono',
        icon: 'create',
        handler: () => {
          this.editMode = true;
        },
      })
    }
    if (this.bono.estado !== BonoConstants.FINALIZADO) {
      buttons.push(
        {
          text: 'Crear cita',
          icon: 'calendar',
          handler: () => {
            this.crearCita();
          },
        }
      );
      if(this.bono.tipo === '2') {
        buttons.push(
          {
            text: 'Crear cita acudida',
            icon: 'calendar',
            handler: () => {
              this.crearCitaAcudida();
            },
          }
        );
      }
      buttons.push(
        {
          text: 'Compartir Bono',
          icon: 'share',
          handler: () => {
            this.compartirBono();
          },
        }
      );
    }
    buttons.push(
      {
        text: 'Eliminar Bono',
        icon: 'trash',
        handler: () => {
          this.eliminarBono();
        },
      },
    );
    if (this.activeMenu) {
      const actionSheet = await this.actionSheetController.create({
        header: 'BONO',
        cssClass: 'my-custom-class',
        buttons: buttons,
      });
      await actionSheet.present();

      const { role } = await actionSheet.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    }
  }

  async guardar( fBono: NgForm ) {
    if ( fBono.invalid ) { console.log("FORMULARIO IiiiiiiiNVALIDO"); return; }
    if (this.bono.tipo === '1') {
      this.bono.zonas_inicio = JSON.stringify(this.zonasInicioSelectedObj);
      if (!this.bono._id || this.bono._id === '') {
        this.bono.zonas = this.bono.zonas_inicio;
      }
    }
    this.bono.cliente = this.cliente._id;
    if(this.bono._id && this.bono._id !== '') {
      if (this.bono.tipo === '1') {
        this.citaService.recarcularCitasPendientesLaser(this.bono);
      } else {
        this.citaService.recarcularCitasPendientes(this.bono);
      }
    } else {
      this.bonoService.addOrUpdateBono(this.bono).then((resp)=>{
        this.bono._id = resp;
      });
    }
    this.cargarNombreBono();
    if(this.editMode) {
      this.updateServicio();
    }
    this.editMode = false;
    if (this.bono.tipo == '1') {
      this.descargarZonas();
    }
  }

  public cargarZonas() {
    let zonas: Zona[] = [];
      this.zonasSelected.forEach(zona => {
        if(this.zonasInicioSelectedObj.find(z => z.nombre === zona)) {
          zonas.push(this.zonasInicioSelectedObj.find(z => z.nombre === zona));
        } else {
          zonas.push({nombre:zona, sesiones:this.bono.sesiones});
        }
        
      });
      this.zonasInicioSelectedObj = zonas;
  }

  public cancelar(): void{
    this.editMode = false;
    // this.cliente = Object.assign({}, this.clienteCopia);

  }

  private eliminarBono(): void {
    this.bonoService.eliminarBono(this.bono._id).then(()=>{
      this.citaService.eliminarCitasByBono(this.bono._id);
      this.returEvento.emit('actualizarBonos');
    });
  }

  private async crearCita(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalFormularioCitaComponent,
      componentProps: {
        cita: {bono: this.bono, cliente: this.cliente, servicio: this.servicio},
        cliente: this.cliente,
        isModal: true
      }
    });
    modal.onDidDismiss().then(res => {
      this.updateBono();
      this.updateCitas();
    });
    return await modal.present();    
  }

  private crearCitaAcudida() {
    const fecha = new Date();
    const cita: Cita = {
      bono: this.bono._id,
      cliente: this.cliente._id,
      estado: citasEstadosConstants.ACUDIDA,
      fecha: fecha.toString(),
      servicio: this.servicio,
      revision: false
    };
    this.citaService.addOrUpdateCita(cita, this.bono).then(() => {
      this.updateBono();
      this.updateCitas();
    });
  }

  private async gestionarBono(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalGestionBonoComponent,
      componentProps: {
        cliente: this.cliente,
        bono: this.bono,
        citas: this.citas,
        showClienteBono: false
      }
    });
    modal.onDidDismiss().then(res => {
      this.updateCitas();
      this.updateBono();
    });
    return await modal.present();    
  }

  public updateCitas() {
    this.citaService.getCitasByBono(this.bono._id).subscribe(citas=> {
      this.citas = citas['citas'];
    });
  }

  public updateBono() {
    this.bonoService.getBonoById(this.bono._id).subscribe(resp=> {
      this.bono = resp['bono'];
      if(this.bono.tipo === '1') {
        this.descargarZonas();
      }
    });
  }

  public updateServicio() {
   this.serviServicio.getServicioByTipoBono(this.bono.tipo).subscribe(resp => {
      this.servicio = resp['servicio'];
   })
  }

  private async compartirBono() {

    const modal = await this.modalCtrl.create({
      component: ModalTransferirBonoComponent,
      componentProps: {
        bono: this.bono,
      }
    });
    modal.onDidDismiss().then(res => {
      this.updateBono();
      this.updateCitas();
    });
    return await modal.present();   

  }

}
