import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Bono, Cita, Zona } from '../interfaces/interfaces';
import { UsuarioService } from './usuario.service';
import { citasEstadosConstants } from '../components/cita/citas.estados.constants';
import { BonoService } from './bono.service';
import { BonoConstants } from '../components/bono/bono.constant';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class CitaService {


  constructor( private http: HttpClient,
              private usuarioService: UsuarioService,
              private bonoService: BonoService) { }


  public addOrUpdateCita(cita: Cita, bono: Bono) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise<string>( resolve => {
      if (cita._id && cita._id !== '') {
        this.actualizarCita(cita, headers).then(async resp => {
            await this.recalcularBonos(bono);
            return resolve(resp);
        });
      } else {
        if(cita.estado !== citasEstadosConstants.ACUDIDA) {
          cita.estado = citasEstadosConstants.PROGRAMADA;
        }
        //cita.revision = false;
        this.crearCita(cita, headers).then(async resp => {
          if(resp !== '') {
            await this.recalcularBonos(bono);
            return resolve(resp);
          }
        });
      
      }
    });
  }

  private async recalcularBonos(bono: Bono) {
    if (bono.tipo === '1') {
      await this.recarcularCitasPendientesLaser(bono);
    } else {
      await this.recarcularCitasPendientes(bono);
    }
  }

  private crearCita( cita: Cita, headers: HttpHeaders) {
    return new Promise<string>( resolve => {
      this.http.post(`${ URL }/cita/create`, cita, {headers})
        .subscribe( resp => {
          if(resp['ok']) {
            const citaN = resp['citaBD']
            resolve(citaN._id);
          }
          resolve('');
        });
    });
  }

  private actualizarCita( cita: Cita, headers: HttpHeaders) {
    return new Promise<string>( resolve => {
      this.http.post(`${ URL }/cita/update`, cita, {headers})
        .subscribe( resp => {
          resolve(cita._id);
      });
    });
  }

  public crearCitaSimple( cita: Cita) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/cita/create`, cita, {headers})
        .subscribe( resp => {
          resolve(true);
        });
    });
  }

  public actualizarCitaSimple( cita: Cita) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/cita/update`, cita, {headers})
        .subscribe( resp => {
          resolve(true);
      });
    });
  }

  public guardarProposicion( cita: Cita) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/cita/proposicion`, cita, {headers})
        .subscribe( resp => {
          resolve(true);
      });
    });
  }

  public actualizarEmpleadoCita( cita: Cita) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/cita/updateEmpleado`, cita, {headers})
        .subscribe( resp => {
          resolve(true);
      });
    });
  }

  public getCitasByBono(citaId: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/cita/${ citaId }`, {headers});
  }
  
  public getCountEstados() {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/cita/countestados`, {headers});
  }

  public getCitasByEstado(estado: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post<Cita[]>(`${ URL }/cita/estado`, {estado: estado}, {headers});
  }

  public eliminarCita(cita: Cita) {
    return new Promise( resolve => {
      const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
      });
      return this.http.get<Cita>(`${ URL }/cita/delete/${ cita._id }`, {headers}).subscribe(resp =>{
        if(cita.bono) {
          this.recalcularBonos(cita.bono as Bono);
        }
        return resolve(true);
      }); 
    });

  }

  public eliminarCitasByBono(bonoId: string) {
    return new Promise( resolve => {
      const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
      });
      return this.http.get<Cita>(`${ URL }/cita/deleteByBono/${ bonoId }`, {headers}).subscribe(resp =>{
        return resolve(true);
      }); 
    });

  }

  public getCitasProgramadasHoy() {
    // DESDE LAS 00:00 DE HOY
    const currentDesde = new Date();
    currentDesde.setHours(0);
    currentDesde.setMinutes(0);
    currentDesde.setSeconds(0);
    currentDesde.setMilliseconds(0);
    const fechaDesde = currentDesde.toISOString();
    // HASTA LAS 23:59 HOY
    const currentHasta = new Date();
    currentHasta.setHours(23);
    currentHasta.setMinutes(59);
    currentHasta.setSeconds(0);
    currentHasta.setMilliseconds(0);
    const fechaHasta = currentHasta.toISOString();

    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/cita/citasprogramadas/fechas?fechaDesde=${ fechaDesde }&fechaHasta=${ fechaHasta }`, {headers});
  }

  public getCitasProgramadasByFecha(fecha: Date) {
    fecha.setHours(0);
    fecha.setMinutes(0);
    fecha.setSeconds(0);
    fecha.setMilliseconds(0);
    const fechaDesde = fecha.toISOString();
    // HASTA LAS 23:59 HOY
    const currentHasta = Object.assign(fecha);
    currentHasta.setHours(23);
    currentHasta.setMinutes(59);
    currentHasta.setSeconds(0);
    currentHasta.setMilliseconds(0);
    const fechaHasta = currentHasta.toISOString();

    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/cita/citasprogramadas/fechas?fechaDesde=${ fechaDesde }&fechaHasta=${ fechaHasta }`, {headers});
  }

  public getCitasByFecha(fecha: Date) {
    fecha.setHours(0);
    fecha.setMinutes(0);
    fecha.setSeconds(0);
    fecha.setMilliseconds(0);
    const fechaDesde = fecha.toISOString();
    // HASTA LAS 23:59 HOY
    const currentHasta = Object.assign(fecha);
    currentHasta.setHours(23);
    currentHasta.setMinutes(59);
    currentHasta.setSeconds(0);
    currentHasta.setMilliseconds(0);
    const fechaHasta = currentHasta.toISOString();

    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/cita/citas/fechas?fechaDesde=${ fechaDesde }&fechaHasta=${ fechaHasta }`, {headers});
  }

  public getCitasByFechaEmpleado(fecha: Date, empleado: string) {
    fecha.setHours(0);
    fecha.setMinutes(0);
    fecha.setSeconds(0);
    fecha.setMilliseconds(0);
    const fechaDesde = fecha.toISOString();
    // HASTA LAS 23:59 HOY
    const currentHasta = Object.assign(fecha);
    currentHasta.setHours(23);
    currentHasta.setMinutes(59);
    currentHasta.setSeconds(0);
    currentHasta.setMilliseconds(0);
    const fechaHasta = currentHasta.toISOString();

    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/cita/citas/fechasEmpleado?fechaDesde=${ fechaDesde }&fechaHasta=${ fechaHasta }&empleado=${ empleado }`, {headers});
  }

  public async recarcularCitasPendientes(bono: Bono) {
    this.getCitasByBono(bono._id).subscribe(async resp=> {
      const citas: Cita[] = resp['citas'];
      const citasRest: Cita[] = citas.filter(c=>
        c.estado === citasEstadosConstants.CANCELADA_RESTA ||
        (c.estado === citasEstadosConstants.ACUDIDA && c.revision === false) ||
        c.estado === citasEstadosConstants.PTE_VALIDAR
      );
        const totalPendiente = bono.sesiones - citasRest.length;
        bono.sesiones_pendientes = totalPendiente;
        if (totalPendiente === 0) {
          bono.estado = BonoConstants.FINALIZADO;
        } else {
          bono.estado = BonoConstants.ACTIVO;
        }
        await this.bonoService.addOrUpdateBono(bono);
    })
  }

  public async recarcularCitasPendientesLaser(bono: Bono) {
    console.log('bono', bono);
    await this.getCitasByBono(bono._id).subscribe( async resp=> {
      const citas: Cita[] = resp['citas'];
      const citasRest: Cita[] = citas.filter(c=>
        c.estado === citasEstadosConstants.CANCELADA_RESTA ||
        (c.estado === citasEstadosConstants.ACUDIDA && c.revision === false) ||
        c.estado === citasEstadosConstants.PTE_VALIDAR
      );
      let zonasInicioArr: Zona[] = JSON.parse(bono.zonas_inicio);
      let zonasArr: Zona[] = JSON.parse(bono.zonas);
      zonasInicioArr.forEach(zi => {
        zonasArr.forEach(z=> {
          if(z.nombre === zi.nombre) {
            z.sesiones = zi.sesiones;
          }
        });
      });
     await citasRest.forEach(cita => {
        const zonasCita: string[] = JSON.parse(cita.zonas);
        zonasCita.forEach(zonacita => {
          const indexZona = zonasArr.findIndex((zona => zona.nombre === zonacita));
          zonasArr[indexZona].sesiones = zonasArr[indexZona].sesiones - 1;
        });
      });
      const consesiones = zonasArr.filter(z=> z.sesiones!==0);
      /*const totalPendiente = bono.sesiones - citasRest.length;
      bono.sesiones_pendientes = totalPendiente;*/
      if (consesiones.length === 0) {
        bono.sesiones_pendientes = 0;
        bono.estado = BonoConstants.FINALIZADO;
      } else {
        bono.sesiones_pendientes = 1;
        bono.estado = BonoConstants.ACTIVO;
      }
      bono.zonas = JSON.stringify(zonasArr);
      await this.bonoService.addOrUpdateBono(bono);
    })
  }

  public getCitasByCliente(clienteId: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/cita/cliente/${ clienteId }`, {headers});
  }
}
