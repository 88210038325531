import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Cliente } from 'src/app/interfaces/interfaces';
import { ModalFichaClienteComponent } from '../modal-ficha-cliente/modal-ficha-cliente.component';

@Component({
  selector: 'app-client-resume',
  templateUrl: './client-resume.component.html',
  styleUrls: ['./client-resume.component.scss'],
})
export class ClientResumeComponent implements OnInit {

  @Input()
  public cliente: Cliente;
  @Input()
  public showButtons: boolean = false;
  @Output()
  editDataUser = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {}

  public editUser() {
    this.editDataUser.emit();
  }

}
