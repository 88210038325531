
export class ServiciosConstants {

    static readonly areas = [
        {
            id: 1,
            nombre: 'PELUQUERIA',
            descripcion: 'Corte, peinados, tintes, tratamientos...',
        },
        {
            id: 2,
            nombre: 'ESTÉTICA',
            descripcion: 'Cera, lifting, tinte de cejas...',
        },
        {
            id: 3,
            nombre: 'ESTÉTICA AVANZADA',
            descripcion: 'Tratamientos faciales y corporales, micropinmentación...',
        },
        {
            id: 4,
            nombre: 'MEDICINA ESTÉTICA',
            descripcion: 'Botox, ácido hialurónico, rinomodelación, mesoterapia...',
        },
        {
            id: 5,
            nombre: 'GESTIÓN INTERNA',
            descripcion: 'Gestión Interna',
        }

    ];

    static readonly zonasCera: string[] = [
        'Cejas (Mantenimiento)',
        'Labio superior',
        'Entrecejo',
        'Piernas completas',
        'Medias piernas',
        'Ingles (Solo mujer)',
        'Ingles completas (Solo mujer)',
        'Brazos',
        'Glúteos (Solo mujer)',
        'Entrepecho (Mujer)',
        'Pecho',
        'Espalda',
        'Diseño de cejas',
        'Axilas'
    ];

}
