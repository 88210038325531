import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Servicio } from '../interfaces/interfaces';
import { UsuarioService } from './usuario.service';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor( private http: HttpClient,
    private usuarioService: UsuarioService) { }

  public getServiciosByArea(area: number) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Servicio[]>(`${ URL }/servicios/${ area }`, {headers});
  }

  public getServicioByTipoBono(tipoBono: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Servicio>(`${ URL }/servicios/bono/${ tipoBono }`, {headers});
  }

  public actualizar(servicio: Servicio) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post(`${ URL }/servicios/update/${ servicio._id }`, servicio, {headers});
  }

  public crear(servicio: Servicio) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post(`${ URL }/servicios/create/`, servicio, {headers});
  }

  }
