import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';

import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  public isMobile = this.deviceDetector.isMobile();

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private deviceDetector: DeviceDetectorService,
    private androidPermissions: AndroidPermissions
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
     /* this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.SMS).then(
        result => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.SMS),
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.SMS)
      );
      this.androidPermissions.requestPermissions([
        this.androidPermissions.PERMISSION.SMS,
        this.androidPermissions.PERMISSION.CAMERA,
        this.androidPermissions.PERMISSION.CALL_PHONE
      ]);*/
    });
  }
}
