import { tipoBono, ZonaServicio } from "src/app/interfaces/interfaces";

export class BonoConstants {
    static readonly zonas: string[] = [
        'Piernas completas',
        'Medias piernas',
        'Pies',
        'Pubis',
        'Ingles normales',
        'Ingles completas',
        'Intergluteos',
        'Axilas',
        'Brazos',
        'Medios Brazos',
        'Manos',
        'Facial completo',
        'Labio superior',
        'Menton',
        'Patillas',
        'Pomulos',
        'Entrecejo',
        'Linea alba',
        'Entrepecho',
        'Areolas',
        'Gluteos',
        'Espalda',
        'Lumbar',
        'Pecho',
        'Abdomen',
        'Hombros',
        'Nuca',
        'Cuello',
        'Orejas'
    ];

    static readonly zonasServicio: ZonaServicio[] = [
        {nombre:'Piernas completas', tiempo: 0},
        {nombre:'Medias piernas', tiempo: 0},
        {nombre:'Pies', tiempo: 0},
        {nombre:'Pubis', tiempo: 0},
        {nombre:'Ingles normales', tiempo: 0},
        {nombre:'Ingles completas', tiempo: 0},
        {nombre:'Intergluteos', tiempo: 0},
        {nombre:'Axilas', tiempo: 0},
        {nombre:'Brazos', tiempo: 0},
        {nombre:'Medios Brazos', tiempo: 0},
        {nombre:'Manos', tiempo: 0},
        {nombre:'Facial completo', tiempo: 0},
        {nombre:'Labio superior', tiempo: 0},
        {nombre:'Menton', tiempo: 0},
        {nombre:'Patillas', tiempo: 0},
        {nombre:'Pomulos', tiempo: 0},
        {nombre:'Entrecejo', tiempo: 0},
        {nombre:'Linea alba', tiempo: 0},
        {nombre:'Entrepecho', tiempo: 0},
        {nombre:'Areolas', tiempo: 0},
        {nombre:'Gluteos', tiempo: 0},
        {nombre:'Espalda', tiempo: 0},
        {nombre:'Lumbar', tiempo: 0},
        {nombre:'Pecho', tiempo: 0},
        {nombre:'Abdomen', tiempo: 0},
        {nombre:'Hombros', tiempo: 0},
        {nombre:'Nuca', tiempo: 0},
        {nombre:'Cuello', tiempo: 0},
        {nombre:'Orejas', tiempo: 0},
    ]

    static readonly ACTIVO = 'ACTIVO';
    static readonly FINALIZADO = 'FINALIZADO';

    static readonly tipoBonos: tipoBono[] = [
        {id: '1', nombre: 'Depilación Láser'},
        {id: '2', nombre: 'Rayos UVA'},
        {id: '3', nombre: 'Presoterapia'},
        {id: '4', nombre: 'Electromusculación'},
        {id: '5', nombre: 'Diatermia Corporal'},
        {id: '6', nombre: 'Diatermia Facial'},
        {id: '7', nombre: 'Mesoterapia Corporal'},
        {id: '8', nombre: 'Mesoterapia Facial'},
        {id: '9', nombre: 'Dermapen'},
        {id: '10', nombre: 'Tto Cosmético Facial'},
        {id: '11', nombre: 'Tto Cosmético Corporal'},
        {id: '12', nombre: 'Presoterapia EYER'},
        {id: '13', nombre: 'Luz led'},
    ];

    static readonly zonasCera: ZonaServicio[] = [
        {nombre:'Cejas (Mantenimiento)', tiempo: 0},
        {nombre:'Labio superior', tiempo: 0},
        {nombre:'Entrecejo', tiempo: 0},
        {nombre:'Piernas completas', tiempo: 0},
        {nombre:'Medias piernas', tiempo: 0},
        {nombre:'Ingles (Solo mujer)', tiempo: 0},
        {nombre:'Ingles completas (Solo mujer)', tiempo: 0},
        {nombre:'Axilas', tiempo: 0},
        {nombre:'Brazos', tiempo: 0},
        {nombre:'Glúteos (Solo mujer)', tiempo: 0},
        {nombre:'Entrepecho (Mujer)', tiempo: 0},
        {nombre:'Pecho', tiempo: 0},
        {nombre:'Espalda', tiempo: 0},
        {nombre:'Axilas', tiempo: 0},
        {nombre:'Diseño de cejas', tiempo: 0}
    ]

    public static cargarNombreBono(idTipoBono: string): string {
        const tipoBono = BonoConstants.tipoBonos.find(b => b.id === idTipoBono);
        return tipoBono.nombre;
      }
}