import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController, LoadingController, ToastController } from '@ionic/angular';
import { Cita, Cliente, Notificacion, Recordatorio } from 'src/app/interfaces/interfaces';
import { AnotacionService } from 'src/app/services/anotacion.service';
import { CitaService } from 'src/app/services/cita.service';
import { PushService } from 'src/app/services/push.service';
import { RecordatorioService } from 'src/app/services/recordatorio.service';
import { WhatsappService } from 'src/app/services/whatsapp.service';

@Component({
  selector: 'app-modal-notificaciones',
  templateUrl: './modal-notificaciones.component.html',
  styleUrls: ['./modal-notificaciones.component.scss'],
})
export class ModalNotificacionesComponent implements OnInit {

  public show = 'whatsapp';
  public showSub = 'hoy';

  public recordatorios: Recordatorio[] = [];

  public citasHoy: Cita[] = [];
  public citasTomorrow: Cita[] = [];

  public enviandoNotificaciones = false;

  public fecha;

  public otroDia = false;

  constructor(private modalCtrl: ModalController,
    private whatsappService: WhatsappService,
    private citaService: CitaService,
    private toastController: ToastController,
    private recordatorioService: RecordatorioService,
    private datePipe: DatePipe) { }

  ngOnInit() {
    const today = new Date();
    this.fecha = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)), 'yyyy-MM-dd');
    this.getRecordatoriosHoy();
    this.getCitasHoy();
    this.getCitasTomorrow();
  }

  cerrarModal() {
    this.modalCtrl.dismiss();
  }

  public enviarNotificaciones() {
    this.enviandoNotificaciones = true;
    setTimeout(() => {
      this.enviandoNotificaciones = false
      this.presentToastRecordatorio();
    }, 6000);
    this.citasHoy.forEach( async c => {
      const fecha = new Date(c.fecha);
      const hora = fecha.getHours()+':'+ (fecha.getMinutes()<10?'0':'') + fecha.getMinutes();
      if(c.enviarWS) {
        this.whatsappService.enviorRecordatorioCitaHoy('34'+(c.cliente as Cliente).movil, hora);
      }
    })
    this.citasTomorrow.forEach( async c => {
      const fecha = new Date(c.fecha);
      const hora = fecha.getHours()+':'+ (fecha.getMinutes()<10?'0':'') + fecha.getMinutes();
      if(c.enviarWS) {
        this.whatsappService.enviorRecordatorioCitaTomorrow('34'+(c.cliente as Cliente).movil, hora);
      }
    })
    this.recordatorios.forEach( async r => {
      if(r.enviarRec) {
        this.whatsappService.enviorRecordatorioSolicitud('34'+(r.cliente as Cliente).movil, r.texto).then(() => {
          this.recordatorioService.mensajeEnviado(r._id);
          r.notificadaWS = true;
        });
      }
    })
  }
  public changePage(page) {
    this.show = page;
  }

  public changePageSub(page) {
    this.showSub = page;
  }

  public changeOtroDia() {
    console.log(this.otroDia);
    if(!this.otroDia) {
      this.getCitasTomorrow();
    } else {
      const today = new Date();
      this.fecha = this.datePipe.transform(new Date(today.setDate(today.getDate() + 1)), 'yyyy-MM-dd');
    }
  }

  
  public getCitasByFecha() {
    this.citaService.getCitasProgramadasByFecha(new Date(this.fecha)).subscribe(resp => {
      this.citasTomorrow = resp['citas'];
      this.citasTomorrow.forEach(c => {
        c.enviarWS = true;
      });
    })
  }

  private getRecordatoriosHoy() {
    this.recordatorioService.getRecordatoriosHoy().subscribe(resp => {
      this.recordatorios = resp['recordatorios'];
      this.recordatorios.forEach(rec => {
        rec.enviarRec = !rec.notificadaWS;
      })
    });
  }

  private getCitasHoy() {
    this.citaService.getCitasProgramadasByFecha(new Date()).subscribe(resp => {
      this.citasHoy = resp['citas'];
      this.citasHoy.forEach(c => {
        c.enviarWS = true;
      });
    })
  }

  private getCitasTomorrow() {
    const today = new Date();
    const tomorrow = new Date(today.setDate(today.getDate() + 1));
    this.citaService.getCitasProgramadasByFecha(tomorrow).subscribe(resp => {
      this.citasTomorrow = resp['citas'];
      this.citasTomorrow.forEach(c => {
        c.enviarWS = true;
      });
    })
  }

  async presentToastRecordatorio() {
    const toast = await this.toastController.create({
      message: 'Recordatorios enviados correctamente',
      position: 'middle',
      duration: 2000,
      color: 'success'
    });
    toast.present();
  }

}
