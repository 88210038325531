import { Component, Input, OnInit } from '@angular/core';
import { Cita, Cliente, Presupuesto } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-presupuesto',
  templateUrl: './presupuesto.component.html',
  styleUrls: ['./presupuesto.component.scss'],
})
export class PresupuestoComponent implements OnInit {

  @Input()
  presupuesto: Presupuesto;

  constructor() { }

  ngOnInit() {}

}
