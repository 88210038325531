import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  export class UtilsService {

    public generarPass(): string {
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 4; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    public mensajeAltaWhatsapp(email:string, password: string) {
        return 'Hola! ha sido dad@ de alta en la app de '
        + 'EPOKAS. Descargue la app en https://app.epokas.com y entre con los siguientes datos: %0a'
        + '- Usuario: *'+ email +'*'
        + '%0a- Contraseña: *'+ password +'*'
        + '%0aNota: Le pedira cambiar la contraseña la primera vez.';
    }

    public mensajeResetWhatsapp(password: string) {
        return 'Hola! se ha reseteado su contraseña en la app de EPOKAS'
        + '%0a- Contraseña: *'+ password +'*'
        + '%0aNota: Le pedira cambiar la contraseña.';
    }
}