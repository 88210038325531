import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController, LoadingController } from '@ionic/angular';
import { Cliente, Notificacion, Recordatorio } from 'src/app/interfaces/interfaces';
import { PushService } from 'src/app/services/push.service';
import { RecordatorioService } from 'src/app/services/recordatorio.service';

@Component({
  selector: 'app-modal-programar-solicitud',
  templateUrl: './modal-programar-solicitud.component.html',
  styleUrls: ['./modal-programar-solicitud.component.scss'],
})
export class ModalProgramarSolicitudComponent implements OnInit {

  @Input()
  public cliente: Cliente;

  public fecha = '';
  public texto = '';

  loading: any;

  public titulo: string = '';

  constructor(private modalCtrl: ModalController,
    private pushService: PushService,
    private recordatorioService: RecordatorioService,
    private loadingCntrl: LoadingController) { }

  ngOnInit() {
  }

  cerrarModal() {
    this.modalCtrl.dismiss();
  }

  async presentLoading() {
    this.loading = await this.loadingCntrl.create({
      message: 'Cargando'
    });
    return await this.loading.present();
  }

  public enviar(fCita: NgForm) {
    if(fCita.invalid) {return;}
    const notificacion: Notificacion = {
      titulo: 'RECORDATORIO!!!',
      texto: this.texto,
      cliente: this.cliente._id,
      pushId: this.cliente.pushId,
      fecha: this.fecha,
    }
    this.pushService.envioProgramado(notificacion).then(() => {
      const recordatorio: Recordatorio = {
        cliente: this.cliente._id,
        texto: this.texto,
        fecha: this.fecha,
      }
      this.recordatorioService.crearRecordatorio(recordatorio).then(() => {
        this.cerrarModal();
      });
    });
  }

}
