import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Anotacion, Bono, Cita, Cliente, Recordatorio } from 'src/app/interfaces/interfaces';
import { BonoConstants } from '../bono/bono.constant';
import { ClienteService } from 'src/app/services/cliente.service';
import { BonoService } from 'src/app/services/bono.service';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { UtilsService } from 'src/app/services/utils.service';
import { CitaService } from 'src/app/services/cita.service';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ModalFormularioCitaComponent } from '../modal-formulario-cita/modal-formulario-cita.component';
import { ImagesService } from 'src/app/services/images.service';
import { environment } from 'src/environments/environment';
import { RecordatorioService } from 'src/app/services/recordatorio.service';
import { AnotacionService } from 'src/app/services/anotacion.service';
import { ModalCrearAnotacionComponent } from '../modal-crear-anotacion/modal-crear-anotacion.component';

declare var window: any;

@Component({
  selector: 'app-ficha-cliente',
  templateUrl: './ficha-cliente.component.html',
  styleUrls: ['./ficha-cliente.component.scss'],
})
export class FichaClienteComponent implements OnInit {

  @Input()
  public cliente: Cliente;

  @Output()
  public cerrarModal = new EventEmitter<string>();

  @Input()
  public desdeAgenda = false;

  public editMode: boolean = false;
  
  private clienteCopia: Cliente;

  public bonos: Bono[];

  public show = 'citas';

  public citas: Cita[] = [];

  public recordatorios: Recordatorio[] = [];
  public anotaciones: Anotacion[] = [];

  public formData = new FormData();

  public carpetas: string[] = [];
  public carpetaAbierta: string;
  public imagesCarpeta: string[] = [];

  public urlBase = environment.url;

  public showMovilError = false;
  public showEmailError = false;

  constructor(private camera: Camera,
    private utilsService: UtilsService,
    private clienteService: ClienteService,
    private modalCtrl: ModalController,
    private imagesService: ImagesService,
    private cntrlPovover: PopoverController,
    private alertController: AlertController,
    private citaService: CitaService,
    private recordatorioService: RecordatorioService,
    private anotacionService: AnotacionService,
    private bonoService: BonoService ) { }

  ngOnInit() {
    if (!this.cliente) {
      this.initCliente();
      this.editMode = true;
    } else {
      this.getBonos();
      this.getCitas();
      this.getRecordatorios();
      this.getAnotaciones();
      this.getCarpetas(false);
    }
  }

  async enviar( fCLiente: NgForm ) {

    if ( fCLiente.invalid ) { console.log("FORMULARIO invalido"); return; }

    if ((!this.cliente._id || this.cliente._id === '')) {
      this.cliente.cambioPassword = true;
      this.cliente.password = this.utilsService.generarPass();
    }

    this.clienteService.addOrUpdateCliente(this.cliente).subscribe(resp => {
      if(resp['ok']) {
        if ((!this.cliente._id || this.cliente._id === '')) {
          const url = 'https://wa.me/34'+ this.cliente.movil+'?text='
          + this.utilsService.mensajeAltaWhatsapp(this.cliente.email, this.cliente.password);
          window.open(url, "_blank");
        }
        this.editMode = false;
        if(this.desdeAgenda) {
          this.modalCtrl.dismiss();
        }
      } else {
        this.showEmailError = resp['err']?.keyPattern?.hasOwnProperty('email');
        this.showMovilError = resp['err']?.keyPattern?.hasOwnProperty('movil');
      }
    }, error => console.log(error.keyPattern));
  }

  public cancelar(): void{
    if (this.cliente._id) {
      this.editMode = false;
      this.cliente = Object.assign({}, this.clienteCopia);
    } else {
      this.cerrarModal.emit();
    }

  }

  public abrirCarpeta(nombre) {
    this.carpetaAbierta = nombre;
    this.getImagesCarpeta(nombre);
  }

  public crearCarpeta(carpeta) {
    if(carpeta) {
      this.imagesService.crearCarpeta(this.cliente._id, carpeta).subscribe(() => {
        this.getCarpetas(false)
        this.carpetaAbierta = carpeta;
        this.cntrlPovover.dismiss();
      })
    }
  }

  public subirImagen(even) {
    const photo = even.target.files[0];
    this.formData = new FormData();
    this.formData.append("photo", photo, photo.name);
    this.imagesService.subirImagen(this.formData, this.cliente._id, this.carpetaAbierta).then(resp => {
      this.getImagesCarpeta(this.carpetaAbierta);
    })
  }

  private initCliente(): void {
    this.cliente = {_id:'', nombre:'', email:'', movil:''}
  }

  private getBonos(): void {
    this.bonoService.getBonosByCliente(this.cliente._id).subscribe(bonos=> {
      this.bonos = bonos['bonos'];
    })
  }

  private getCitas(): void {
    this.citaService.getCitasByCliente(this.cliente._id).subscribe(resp=> {
      this.citas = resp['citas'];
    })
  }

  public getRecordatorios() {
    this.recordatorioService.getRecordatorioByCliente(this.cliente._id).subscribe(resp => {
      this.recordatorios = resp['recordatorios'];
    })
  }

  public getAnotaciones() {
    this.anotacionService.getAnotacionesByCliente(this.cliente._id).subscribe(resp => {
      this.anotaciones = resp['anotaciones'];
    })
  }

  async crearAnotacion() {
    const modal = await this.modalCtrl.create({
      component: ModalCrearAnotacionComponent,
      componentProps: {
        cliente: this.cliente
      }
    });
    modal.onDidDismiss().then(res => {
      this.getAnotaciones();
    });
    return await modal.present();
  }

  private getCarpetas(fromDelete: boolean) {
    this.imagesService.getCarpetas(this.cliente._id).subscribe(resp => {
      this.carpetas = resp['lista'];
      if(!this.carpetaAbierta || fromDelete) {
        this.carpetaAbierta = this.carpetas[0];
      }
      this.getImagesCarpeta(this.carpetaAbierta);
    });
  }

  private getImagesCarpeta(nombreCarpeta) {
    this.imagesService.getImagesCarpetas(this.cliente._id, nombreCarpeta).subscribe(resp => {
      this.imagesCarpeta = resp['lista'];
    })
  }

  public editDataUser(event) {
    this.editMode = true;
    this.clienteCopia = Object.assign({}, this.cliente);
  }

  public eventosBonos(event: string) {
    switch (event) {
      case 'actualizarBonos':
        this.getBonos();
        this.getCitas();
        break;
    
      default:
        break;
    }
  }

  public addBono() {
    if (!this.bonos) {
      this.cliente.bonos = [];
    }
    this.bonos.unshift(
      {_id: undefined, sesiones:0, tipo: undefined, estado:BonoConstants.ACTIVO, sesiones_pendientes:0, cliente:''})
  }

  public changePage(page) {
    this.show = page;
  }

  public async crearCita(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalFormularioCitaComponent,
      componentProps: {
        cita: {},
        cliente: this.cliente
      }
    });
    modal.onDidDismiss().then(res => {
      this.getBonos();
      this.getCitas();
    });
    return await modal.present();    
  }
  public eventosDesdeCitas(cita:Cita): void {
    this.getCitas();
    this.getBonos();
  }

  public async eliminarImagen(image: string) {
    const alert = await this.alertController.create({
      header: '¿Estas seguro que quieres eliminar la imagen?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'NO',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'SI',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.imagesService.eliminarImagen(this.cliente._id, this.carpetaAbierta, image).subscribe((resp)=>{
              this.getImagesCarpeta(this.carpetaAbierta);
            });
          }
        },
      ],
    });

    await alert.present();
  }

  public async eliminarCarpeta() {
    const alert = await this.alertController.create({
      header: '¿Estas seguro que quieres eliminar la carpeta '+ this.carpetaAbierta+'?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'NO',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'SI',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.imagesService.eliminarCarpeta(this.cliente._id, this.carpetaAbierta).subscribe((resp)=>{
              this.getCarpetas(true);
            });
          }
        },
      ],
    });

    await alert.present();
  }
  
  camara() {

    const options: CameraOptions = {
      quality: 60,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType: this.camera.PictureSourceType.CAMERA
    };

    this.procesarImagen( options );

  }

  libreria() {

    const options: CameraOptions = {
      quality: 60,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
    };

    this.procesarImagen( options );

  }


  procesarImagen( options: CameraOptions ) {

    this.camera.getPicture(options).then( ( imageData ) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):

       const img = window.Ionic.WebView.convertFileSrc( imageData );

      // this.postsService.subirImagen( imageData );
      // this.tempImages.push( img );

     }, (err) => {
      // Handle error
     });
  }

}
