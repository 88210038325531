import { Component, Input, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { Cliente } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-modal-ficha-cliente',
  templateUrl: './modal-ficha-cliente.component.html',
  styleUrls: ['./modal-ficha-cliente.component.scss'],
})
export class ModalFichaClienteComponent implements OnInit {

  @Input()
  public cliente: Cliente;

  @Input()
  public desdeAgenda = false;

  loading: any;

  public titulo: string = '';

  constructor(private modalCtrl: ModalController,
    private loadingCntrl: LoadingController) { }

  ngOnInit() {
  }

  cerrarModal() {
    this.modalCtrl.dismiss();
  }

  async presentLoading() {
    this.loading = await this.loadingCntrl.create({
      message: 'Cargando'
    });
    return await this.loading.present();
  }


}
