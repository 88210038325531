import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Notificacion, Promocion } from 'src/app/interfaces/interfaces';
import { PromocionService } from 'src/app/services/promocion.service';
import { PushService } from 'src/app/services/push.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promocion',
  templateUrl: './promocion.component.html',
  styleUrls: ['./promocion.component.scss'],
})
export class PromocionComponent implements OnInit {

  @Input()
  public editMode = false;

  @Input()
  public promo: Promocion;

  @Output()
  actualizarPromo = new EventEmitter<boolean>();

  public urlBaseImage = environment.url+'/promociones/';

  public formData = new FormData();

  public buttons: any[] = [];

  constructor(private modalCtrl: ModalController,
    public actionSheetController: ActionSheetController,
    private pushService: PushService,
    private promoService: PromocionService) { }

  ngOnInit() {
    this.getButonsByEstado();
  }

  public cerrarModal() {
    this.modalCtrl.dismiss();
  }

  public onFileChange(even) {
    const photo = even.target.files[0];
    this.formData.append("photo", photo, photo.name);
  }

  public guardar(fPromo: NgForm ) {
    if(fPromo.invalid){return;}
    if(!this.promo.activa) {
      this.promo.activa = false;
    }
   this.promoService.addOrUpdatePromocion(this.promo, this.formData).then(valido => {
      if(valido) {
        this.cerrarModal();
      }
    });

  }

  async menu() {
    const actionSheet = await this.actionSheetController.create({
      header: 'PROMOCIONES',
      cssClass: 'my-custom-class',
      buttons: this.buttons
    });
    await actionSheet.present();

    await actionSheet.onDidDismiss().then(() => {
      this.actualizarPromo.emit(true);
    });

  }

  private getButonsByEstado() {
    this.buttons = [];
      this.buttons.push({
        text: this.promo.activa? 'Desactivar' : 'Activar',
        icon: 'power-outline',
        handler: () => {
          this.promoService.cambiarEstado(this.promo);
        },
      });
      this.buttons.push({
        text: 'Editar',
        icon: 'checkmark',
        handler: () => {
        },
      });
      this.buttons.push({
        text: 'Enviar notificaciones',
        icon: 'arrow-redo-outline',
        handler: () => {
          const notificacion: Notificacion = {
            titulo: this.promo.titulo,
            texto: this.promo.texto,
            tipo: 'promocion',
            cliente: 'all'
          }
          this.pushService.envioGeneral(notificacion);
        },
      })
      this.buttons.push({
        text: 'Eliminar',
        icon: 'close',
        handler: () => {
          this.promoService.eliminarPromocion(this.promo._id);
        },
      })
  }

}
