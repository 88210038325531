import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { Usuario } from '../interfaces/interfaces';
import { NavController } from '@ionic/angular';

const URL = environment.url;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

 token: string = null;
 private usuario: Usuario = {};

  constructor( private http: HttpClient,
               private storage: Storage,
               private navCtrl: NavController ) { }


  login( email: string, password: string ) {

    const data = { email, password };

    return new Promise( resolve => {

      this.http.post(`${ URL }/user/login`, data )
        .subscribe( async resp => {
          debugger;
          if ( resp['ok'] ) {
            await this.guardarToken( resp['token'] );
            resolve(true);
          } else {
            debugger;
            this.token = null;
            this.storage.clear();
            resolve(false);
          }

        }, error=>{
          debugger;
        });

    });

  }

  logout() {
    this.token   = null;
    this.usuario = null;
    this.storage.clear();
    this.navCtrl.navigateRoot('/', { animated: true });
  }

  public crearUsuario( usuario: Usuario ) {
    const headers = new HttpHeaders({
      'x-token': this.token
    });
    return new Promise<Usuario>( resolve => {
      this.http.post(`${ URL }/user/create`, usuario, {headers} )
          .subscribe( async resp => {
            resolve(resp['user']);
          });
    });
  }

  getUsuario() {

    if ( !this.usuario && !this.usuario._id ) {
      this.validaToken();
    }

    return { ...this.usuario };

  }


  async guardarToken( token: string ) {

    this.token = token;
    await this.storage.set('token', token);

    await this.validaToken();


  }

  async cargarToken() {

    this.token = await this.storage.get('token') || null;

  }


  async validaToken(): Promise<boolean> {

    await this.cargarToken();

    if ( !this.token ) {
      this.navCtrl.navigateRoot('/');
      return Promise.resolve(false);
    }


    return new Promise<boolean>( resolve => {

      const headers = new HttpHeaders({
        'x-token': this.token
      });

      this.http.get(`${ URL }/user/`, { headers })
        .subscribe( resp => {

          if ( resp['ok'] ) {
            this.usuario = resp['usuario'];
            resolve(true);
          } else {
            this.navCtrl.navigateRoot('/login');
            resolve(false);
          }

        });


    });

  }


  public actualizarUsuario( usuario: Usuario ) {

    const headers = new HttpHeaders({
      'x-token': this.token
    });
    return new Promise<Usuario>( resolve => {
      this.http.post(`${ URL }/user/update`, usuario, {headers} )
          .subscribe( async resp => {
            resolve(resp['user']);
          });
    });

  }

  getToken(): Promise<String> {
    return this.storage.get('token');
  }

  public getEmpleados() {
    const headers = new HttpHeaders({
        'x-token': this.token
      });
      return this.http.get<Usuario[]>(`${ URL }/user/empleados`, {headers});
    }

}
