import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UsuarioGuard } from './guards/usuario.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  { path: 'inicio', loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule), canLoad:[ UsuarioGuard ]},
  { path: 'clientes', loadChildren: () => import('./pages/clientes/clientes.module').then( m => m.ClientesPageModule), canLoad:[ UsuarioGuard ]},
  { path: 'promociones', loadChildren: () => import('./pages/promociones/promociones.module').then( m => m.PromocionesPageModule), canLoad:[ UsuarioGuard ]},
  {
    path: 'citas',
    loadChildren: () => import('./pages/citas/citas.module').then( m => m.CitasPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'agenda',
    loadChildren: () => import('./pages/agenda/agenda.module').then( m => m.AgendaPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./pages/configuracion/configuracion.module').then( m => m.ConfiguracionPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'empleados',
    loadChildren: () => import('./pages/empleados/empleados.module').then( m => m.EmpleadosPageModule),
    canLoad: [UsuarioGuard]
  },




  // { path: 'presupuestos', loadChildren: () => import('./pages/presupuestos/presupuestos.module').then( m => ), canLoad:[ UsuarioGuard ]},
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
